<template>
  <Transition name="fade">
    <div v-if="loading" class="loadscreen">
      <div class="loadscreen__status">{{ progresses + '\n' + 'test' }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100"
        :data-data="JSON.stringify($store.state.loads)">
        <circle cx="50" cy="50" r="20" stroke-width="8" stroke="#007bff"
          stroke-dasharray="31.41592653589793 31.41592653589793" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" keyTimes="0;1"
            values="0 50 50;360 50 50"></animateTransform>
        </circle>
      </svg>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex/dist/vuex.cjs.js';

export default {
  data: () => ({
    active: false,
    timer: null
  }),
  created() {
    if (!window.statusCheckerInitialized) {
      window.statusCheckerInitialized = true
      window.statusCheckerInterval =
        setInterval(() => {
          if (window.statusChecker) {
            clearInterval(window.statusCheckerInterval)
            this.updateStatus()
          }
        }, 500)
    }
  },
  methods: {
    setNextStatusCheck() {

    },
    async updateStatus() {
      await fetch(this.url + 'progress', { credentials: 'include' })
        .then(res => res.json())
        .then(json => { this.$store.commit('setStatusString', json) })
        .catch(err => { console.error(err); this.$store.commit('setStatusString', err + '') })
        .finally(() => {
          window.statusCheckerInterval =
            setInterval(() => {
              if (window.statusChecker) {
                clearInterval(window.statusCheckerInterval)
                this.updateStatus()
              }
            }, 500)
        })
    }
  },
  computed: {
    ...mapGetters(['loading','progresses']),
    ...mapState(['url'])
  },
  watch: {
    loading(v) {
      if (v) {
        clearTimeout(this.timer)
        this.active = true
        window.statusChecker = true
      } else {
        this.timer = setTimeout(() => this.active = false, 200)
        window.statusChecker = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.loadscreen
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: white
  z-index: 10000
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  &__status
    white-space: pre-wrap
  svg
    width: 200px
    max-width: 90%
</style>